// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$eipblue: mat.m2-define-palette((50 : #e0f3fb,
      100 : #b3e1f4,
      200 : #80cded,
      300 : #4db9e6,
      400 : #26aae0,
      500 : #009bdb,
      600 : #0093d7,
      700 : #0089d2,
      800 : #007fcd,
      900 : #0062af,
      A100 : #ecf6ff,
      A200 : #b9dcff,
      A400 : #86c3ff,
      A700 : #6db6ff,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$EIP-primary: mat.m2-define-palette($eipblue, 900);
$EIP-accent: mat.m2-define-palette($eipblue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$EIP-warn: mat.m2-define-palette(mat.$m2-red-palette);

//$subheading-1: mat.define-typography-level(14px, 24px, 400),
$EIP-typography: mat.m2-define-typography-config($font-family: 'Roboto, Helvetica Neue, sans-serif',
    $body-1: mat.m2-define-typography-level(14px, 24px, 400),
    $body-2: mat.m2-define-typography-level(14px, 24px, 400),
    $caption: mat.m2-define-typography-level(14px, 24px, 400),
    $button: mat.m2-define-typography-level(14px, 24px, 500));

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$EIP-theme: mat.m2-define-light-theme((color: (primary: $EIP-primary,
        accent: $EIP-accent,
        warn: $EIP-warn,
      ), typography: $EIP-typography));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($EIP-theme);

/* You can add global styles to this file, and also import other style files */
.mat-primary {
  &.mat-mdc-button-base {
    --mat-fab-foreground-color: #fff;
    --mdc-filled-button-label-text-color: #fff;
    --mdc-protected-button-label-text-color: #fff;
  }

  &.mat-mdc-checkbox {
    --mdc-checkbox-select: #fff;
  }

  &.mat-mdc-fab,
  &.mat-mdc-mini-fab {
    --mat-icon-color: #fff;
  }

  --mdc-checkbox-selected-checkmark-color: #fff;
}

.mat-badge-content {
  --mat-badge-text-color: #fff;
}


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 20px;

  color: #333333;

  a {
    color: #333333;

    &:hover {
      color: #0062af;
    }
  }

  input,
  select {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    border-color: #C4C4C4 !important;
    border-width: 1px !important;

    &:hover {
      border-color: #0062af !important;
      border-width: 1px !important;
    }
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control:not(.mat-mdc-form-field-textarea-control) {
    height: 24px;

    .mat-date-range-input-container {
      height: 100%;
    }
  }

  .h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 54px;
    line-height: 63px;
  }

  .h2.light {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
  }

  .h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
  }

  .h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  .h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
      color: #C4C4C4 !important;

      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: 1px !important;
      }
    }

    &:hover {
      .mat-form-field-outline-thick {
        color: #0062af !important;
      }
    }
  }

  .mat-paginator-icon {
    &:hover {
      color: #0062af !important;
    }
  }

  .mat-header-cell {
    color: #333333;
  }

  .mdc-button {
    white-space: nowrap;
  }

  .mat-mdc-raised-button {
    &:not(.mat-primary):not(:disabled) {
      background-color: #E4E4E4;
    }
    box-shadow: none !important;

    &:not([class*=mat-elevation-z]) {
      box-shadow: none !important;
    }

    &:not(.mat-primary):not(:disabled):hover {
      background-color: #F2F2F2;
    }

    &.mat-primary:not(.mat-button-disabled):hover {
      background-color: #00B5FF;
    }
  }

  .mat-tooltip {
    white-space: pre-line !important;
  }
}


mat-form-field {
  margin: .25em 0;
}

mat-error {
  font-size: 10.5px;
}

.mdc-tooltip {
  margin: 0px;
  padding: 8px 6px;

  .mdc-tooltip__surface {
    line-height: 20px;
    font-size: 10px;
    --mdc-plain-tooltip-supporting-text-size: 10px;
  }
}


.red-snackbar {
  background-color: #A32B2D;
  color: white;

  button .mdc-button__label {
    color: white;
  }
}

.green-snackbar {
  background-color: #4caf50;
  color: white;

  button .mdc-button__label {
    color: white;
  }
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  /*click effect color change*/
  background-color: #0062af !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #0062af !important;
  /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0062af !important;
  /*outer ring color change*/
}

.mat-mdc-form-field {
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal
}

.mat-badge-content {
  font-size: 12px;
}

.mat-mdc-menu-item {
  font-weight: 400;
}

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 24px !important;
  }

  .mdc-dialog__title {
    font-family: Roboto, Helvetica Neue, sans-serif !important;
    font-size: 36px !important;
    line-height: 42px !important;
    font-style: normal !important;
    font-weight: normal !important;
    letter-spacing: normal !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .mdc-dialog__content {
    font-family: Roboto, Helvetica Neue, sans-serif !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: normal !important;
    font-weight: normal !important;
    color: #000000de !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.mdc-button__label {
  display: flex !important;
  align-items: center !important;
}

.mat-mdc-checkbox .mdc-checkbox {
  //padding-right: 8px !important;
}

.mdc-form-field>label {
  padding-left: 0px !important;
  margin-left: -4px !important;
}

.mdc-switch__track {
  --mdc-switch-selected-track-color: #0062af8a;
}

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-handle-color: #0062af;
}

.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: #A32B2D;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.green-snackbar {
    --mdc-snackbar-container-color: #4caf50;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mdc-data-table__cell {
  line-height: 20px;
}